import React, { useState } from 'react';
import FacebookIcon from '../atoms/FacebookIcon';
import InstagramIcon from '../atoms/InstagramIcon';
import LinkedInIcon from '../atoms/LinkedInIcon';
import TwitterIcon from '../atoms/TwitterIcon';
import GetInTouchForm from '../molecules/GetInTouchForm';
import FadingLogoStrip from '../molecules/FadingLogoStrip';
import CtaLink from '../atoms/CtaLink';
interface GetInTouchHeroProps {
  title: string;
  body: string;
  formTitle: string;
  getHelpLabel: string;
  connectWithUs: string;
  socialLinks: Array<{ type: string; url: string }>;
  successMessage: {
    title: string;
    body: string;
  };
  departments: {
    name: string;
    description: string;
    cta: {
      to: string;
      label: string;
    };
  }[];
  logos: {
    image: {
      sourceUrl: string;
      altText: string;
    };
  }[];
}

const GetInTouchHero: React.FC<GetInTouchHeroProps> = ({
  title,
  body,
  departments,
  logos,
  formTitle,
  successMessage,
  getHelpLabel,
  connectWithUs,
  socialLinks,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <section
        className="pt-20 pb-10 overflow-hidden bg-cover rounded-bl rounded-br lg:pt-28 lg:pb-40"
        style={{
          backgroundImage:
            'linear-gradient(159.3deg, #F7E69A -48.7%, #EE7272 80.57%)',
        }}
      >
        <div className="flex flex-col text-center text-white u-container">
          <h1 className="mb-3 text-4xl font-semibold md:mb-5 md:text-6xl tracking-pct">
            {title}
          </h1>
          <div
            className="mb-0 text-lg leading-normal md:text-xl md:mb-12"
            dangerouslySetInnerHTML={{ __html: body }}
          />

          <div className="max-w-5xl mx-auto -mt-4 overflow-hidden">
            <FadingLogoStrip logos={logos} />
          </div>
        </div>
      </section>
      <div className="pt-20 space-y-16 md:pt-32 lg:flex lg:space-y-0 lg:space-x-32 u-container">
        <div className="w-full -mt-32 lg:w-7/12 lg:-mt-56">
          <GetInTouchForm
            title={formTitle}
            successTitle={successMessage?.title}
            successBody={successMessage?.body}
          />
        </div>
        <div className="w-full lg:w-5/12">
          <div className="max-w-sm">
            <h2
              className="mb-6 md:mb-10 u-h4"
              dangerouslySetInnerHTML={{ __html: getHelpLabel }}
            />

            <div className="mb-8 space-y-8">
              {departments.map((department, index) => (
                <div key={index}>
                  <h3 className="mb-3 u-h6">{department.name}</h3>
                  <p
                    className="mb-3 text-sm leading-6 text-body-copy"
                    dangerouslySetInnerHTML={{ __html: department.description }}
                  />
                  {department.cta && department.cta?.label && department.cta?.label.length && <CtaLink {...department.cta} />}
                </div>
              ))}
            </div>

            <h2
              className="mb-5 u-h6"
              dangerouslySetInnerHTML={{ __html: connectWithUs }}
            />
            <div className="flex items-center space-x-4">
              {socialLinks.map(({ type, url }, index) => (
                <a
                  key={index}
                  href={url}
                  target="_blank"
                  className="flex items-center justify-center w-12 h-12 duration-200 ease-in-out rounded-full bg-teal text-teal bg-opacity-15 hover:opacity-75"
                >
                  {type === 'Facebook' && <FacebookIcon />}
                  {type === 'Twitter' && <TwitterIcon />}
                  {type === 'Instagram' && <InstagramIcon />}
                  {type === 'LinkedIn' && <LinkedInIcon />}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetInTouchHero;
