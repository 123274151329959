import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import GetInTouchHero from '../components/organisms/GetInTouchHero';
import OurOfficesSection from '../components/organisms/OurOfficesSection';

const ContactPage = ({ data: { page: data } }) => (
  <Layout>
    <Seo post={data} />

    <GetInTouchHero
      {...data.contact.getInTouchHero}
      formTitle={data.contact.getInTouchFormTitle}
      successMessage={data.contact.successMessage}
    />
    <OurOfficesSection {...data.contact.ourOfficesSection} />
  </Layout>
);

export const pageQuery = graphql`
  query ContactQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      slug
      ...SEO
      contact {
        getInTouchHero {
          title
          body
          departments {
            name
            description
            cta {
              label
              to
            }
          }
          logos {
            image {
              ...Image
            }
          }
          getHelpLabel
          connectWithUs
          socialLinks {
            type
            url
          }
        }
        getInTouchFormTitle
        successMessage {
          title
          body
        }
        ourOfficesSection {
          lead
          title
          image {
            ...Image
          }
          offices {
            name
            city
            description
            address
          }
        }
      }
    }
  }
`;

export default ContactPage;
