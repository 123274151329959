import React from 'react';
import CtaLink from '../atoms/CtaLink';

interface OurOfficesSectionProps {
  lead: string;
  title: string;
  image: {
    sourceUrl: string;
    altText: string;
  };
  offices: {
    name: string;
    city: string;
    description: string;
    address: string;
  }[];
}

const OurOfficesSection: React.FC<OurOfficesSectionProps> = ({
  lead,
  title,
  image,
  offices,
}) => (
  <section className="my-16 md:my-32">
    <div className="u-container-max">
      <div className="px-5 mb-12 md:px-0">
        <div className="u-s1">{lead}</div>
        <h1 className="u-h2">{title}</h1>
      </div>
      <img
        src={image?.sourceUrl}
        className="w-full h-auto mb-16 md:mb-20"
        alt={image?.altText}
      />
      <div className="justify-between px-5 space-y-10 lg:flex lg:space-x-9 lg:space-y-0 md:px-0">
        {offices.map(({ name, city, description, address }, index) => (
          <div key={index} className="flex-1">
            <h2 className="mb-2 u-s1">{name}</h2>
            <div className="mb-2 text-3xl font-semibold md:mb-3 md:text-4xl leading-12">
              {city}
            </div>
            <p className="mb-4 leading-relaxed text-body-copy">{description}</p>
            <address className="mb-5 not-italic text-body-copy">
              {address.split('\n').map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
            </address>
            <CtaLink
              label="Get directions"
              to={`https://maps.google.com?q=${address}`}
            />
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default OurOfficesSection;
