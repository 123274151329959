import { ErrorMessage, Formik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

import * as Yup from 'yup';
import { industries } from '../../config/industries';
import { companyType } from '../../config/companyType';
import { mediaSpend } from '../../config/mediaSpend';
import Checkbox from '../atoms/Checkbox';
import FormSubmittedOverlay from './FormSubmittedOverlay';
import usePardotSubmit from '../../hooks/use-pardot-submit';

interface GetInTouchFormProps {
  body?: string;
  title?: string;
  successTitle: string;
  successBody: string;
  hasTitle?: boolean;
}

const GetInTouchForm: React.FC<GetInTouchFormProps> = ({
  body,
  title,
  successTitle,
  successBody,
  hasTitle = true,
}) => {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    department: 'sales',
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    company: '',
    company_type: '',
    industry: '',
    media_spend: '',
    message: '',
    terms: false,
  };

  const schema = Yup.object().shape({
    department: Yup.string().required('This field is required'),
    first_name: Yup.string().required('This field is required'),
    last_name: Yup.string().required('This field is required'),
    phone: Yup.string().required('This field is required'),
    email: Yup.string()
      .email('Please provide valid email')
      .required('This field is required'),
    company: Yup.string().required('This field is required'),
    company_type: Yup.string().required('This field is required'),
    media_spend: Yup.string().required('This field is required'),
    message: Yup.string().required('This field is required'),
    terms: Yup.bool()
      .required('Please agree to the privacy policy')
      .oneOf([true], 'Please agree to the privacy policy'),
  });

  const submit = async (data) => {
    setLoading(true);
    usePardotSubmit(
      'https://go.spirable.com/l/843243/2021-02-26/mpyvs9',
      data,
      setResult,
    );
  };

  useEffect(() => {
    console.log(result);
    setLoading(false);
  }, [result]);

  return (
    <div className="relative p-8 overflow-hidden bg-white rounded-lg lg:p-16 shadow-contact-form md:p-12">
      {hasTitle && (
        <h2 className="mb-4 text-2xl font-semibold leading-tight md:mb-8 md:text-3xl">
          {title}
        </h2>
      )}
      <AnimatePresence>
        {result === 'success' && (
          <FormSubmittedOverlay title={successTitle} body={successBody} />
        )}
      </AnimatePresence>
      {body && (
        <p className="mb-6 font-semibold leading-normal md:text-lg md:mb-11">
          {body}
        </p>
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={submit}
      >
        {({
          values: {
            department,
            first_name,
            last_name,
            phone,
            email,
            company,
            company_type,
            industry,
            media_spend,
            message,
            terms,
          },
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="mb-6 space-y-5 md:mb-12">
              <div className="flex items-center bg-keyline">
                <button
                  type="button"
                  className={`flex-1 flex-grow transition font-semibold py-3  focus:outline-none text-sm md:text-base ${
                    department === 'sales'
                      ? 'bg-primary-red text-white'
                      : 'hover:text-primary-red'
                  }`}
                  onClick={() => setFieldValue('department', 'sales')}
                >
                  Sales
                </button>
                <button
                  type="button"
                  className={`flex-1 flex-grow transition font-semibold py-3  focus:outline-none text-sm md:text-base ${
                    department === 'support'
                      ? 'bg-primary-red text-white'
                      : 'hover:text-primary-red'
                  }`}
                  onClick={() => setFieldValue('department', 'support')}
                >
                  Support
                </button>
                <button
                  type="button"
                  className={`flex-1 flex-grow transition font-semibold py-3  focus:outline-none text-sm md:text-base ${
                    department === 'press'
                      ? 'bg-primary-red text-white'
                      : 'hover:text-primary-red'
                  }`}
                  onClick={() => setFieldValue('department', 'press')}
                >
                  Press
                </button>
              </div>
              <div>
                <input
                  type="text"
                  name="first_name"
                  placeholder="First name"
                  className="u-form-input"
                  value={first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  className="mt-1 text-xs text-red-500"
                  name="first_name"
                  component="div"
                />
              </div>
              <div>
                <input
                  type="text"
                  name="last_name"
                  placeholder="Last name"
                  className="u-form-input"
                  value={last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  className="mt-1 text-xs text-red-500"
                  name="last_name"
                  component="div"
                />
              </div>

              <div>
                <input
                  type="text"
                  name="phone"
                  placeholder="Your phone number"
                  className="u-form-input"
                  value={phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  className="mt-1 text-xs text-red-500"
                  name="phone"
                  component="div"
                />
              </div>

              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your work email"
                  className="u-form-input"
                  value={email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  className="mt-1 text-xs text-red-500"
                  name="email"
                  component="div"
                />
              </div>

              <div>
                <input
                  type="text"
                  name="company"
                  placeholder="Your company"
                  className="u-form-input"
                  value={company}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  className="mt-1 text-xs text-red-500"
                  name="company"
                  component="div"
                />
              </div>

              <div>
                <select
                  className="w-full u-form-select"
                  name="company_type"
                  value={company_type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option>Company Type</option>
                  {companyType.map((value) => (
                    <option value={value} key={value}>
                      {value}
                    </option>
                  ))}
                </select>

                <ErrorMessage
                  className="mt-1 text-xs text-red-500"
                  name="company_type"
                  component="div"
                />
              </div>

              {department === 'sales' && (
                <div>
                  <select
                    className="w-full u-form-select"
                    name="industry"
                    value={industry}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option>Please select your industry</option>
                    {industries.map((value) => (
                      <option value={value} key={value}>
                        {value}
                      </option>
                    ))}
                  </select>

                  <ErrorMessage
                    className="mt-1 text-xs text-red-500"
                    name="industry"
                    component="div"
                  />
                </div>
              )}
              <div>
                <select
                  className="w-full u-form-select"
                  name="media_spend"
                  value={media_spend}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option>Media Spend</option>
                  {mediaSpend.map((value) => (
                    <option value={value} key={value}>
                      {value}
                    </option>
                  ))}
                </select>

                <ErrorMessage
                  className="mt-1 text-xs text-red-500"
                  name="media_spend"
                  component="div"
                />
              </div>
              <div>
                <textarea
                  name="message"
                  placeholder="How can we help?"
                  className="u-textarea"
                  value={message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage
                  className="text-xs text-red-500"
                  name="message"
                  component="div"
                />
              </div>
            </div>

            <div className="mb-6 md:mb-12">
              <Checkbox
                label={
                  <>
                    I consent to receive newsletters and other communications
                    from Spirable. Please read our{' '}
                    <Link
                      to="/privacy"
                      className="underline transition focus:outline-none focus:ring focus:border-primary-red focus:ring-primary-red focus:ring-opacity-20 focus:text-black hover:text-black"
                    >
                      Privacy Policy
                    </Link>{' '}
                    to see how we protect and manage your personal data.
                  </>
                }
                checked={terms}
                onChange={(value) => setFieldValue('terms', value)}
              />
              <ErrorMessage
                className="mt-1 text-xs text-red-500"
                name="terms"
                component="div"
              />
            </div>

            <button
              type="submit"
              className="w-full py-3 font-semibold leading-7 text-white transition-all bg-primary-red focus:outline-none focus:ring focus:border-primary-red focus:ring-primary-red focus:ring-opacity-20 hover:bg-black"
              disabled={loading}
            >
              {loading ? 'Submitting...' : 'Send enquiry'}
            </button>

            {/* {error && (
              <div className="mt-4 text-sm text-primary-red">
                {error.message}
              </div>
            )} */}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default GetInTouchForm;
